.betaElement__containerTodo {
    display: flex;
    margin-bottom: -10px;
}

.betaElement__textareaTodo {
    background-color: white;
    resize: none;
    font-size: 20px;
    font-weight: 300;
}

.betaElement__textareaTitle {
    background-color: white;
    resize: none;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: -20px;

}

.betaElement__textareaComment {
    background-color: white;
    resize: none;
    font-size: 20px;
    font-weight: 300;
    color: gray
}

.betaElement__checkWrapper {

    margin-bottom: 10px;
}

.betaElement__checkIcon {
    margin-top: 11px;
    width: 18px;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    border-color: #E2E2E2;
    height: 18px;

}


.betaElement__checkIconCompleted {
    margin-top: 11px;
}


.colabToDo__xbutton {
    width: 12px;
    position: absolute;
    right: 0;
    margin-right: 8px;
    background-color: transparent;
    font-size: small;
    border-radius: 50%;
    margin-top: -9px;

    height: 4px;
}

.colabToDo__dragger {
    position: absolute;
}