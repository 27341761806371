.daw-showcase__container {
    position: relative;
    /* Big enough so we have multiple “screenfuls” to scroll through */
    height: 400vh;
    /* or some big number like 300vh, 500vh, etc. */
    display: flex;
    width: 100%;
}

/* This is the sticky “viewport” that locks in place once it scrolls into view */
.daw-showcase__sticky-viewport {
    position: sticky;
    top: 0;
    /* stick to the top of the viewport */
    height: 100vh;
    /* it occupies the full screen height */
    width: 100%;
    overflow: hidden;
    /* so children can’t overflow */
}

/* The plugin that stays pinned in the center (or top) of the sticky viewport */
.daw-showcase__plugin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    /* on top of images */
}

.plugin__image {
    max-width: 400px;
    width: 100%;
    height: auto;
    /* adjust as you like */
}

/* Each DAW image is absolutely positioned in the sticky area */
.daw-showcase__image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-out;
    z-index: 1;
    /* behind the plugin but in front of each other (stacked) */
}

.daw-showcase__image {
    max-width: 80vw;
    height: auto;
    object-fit: contain;
    /* Adjust if you prefer a different sizing approach */
}

.daw-showcase__wrapper{
    width: 100%;
}




.sticky__container {}

.sticky__wrapper {}

.sticky__content {}

.daw-showcase__plugin{
    width: 700px;
    height: 500px;
    background-color: white;
    box-shadow: 3px 3px 10px #03030325;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
}