@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300&family=Rubik:wght@400;800&display=swap');


textarea{
  outline: none;
  border: none;
    background-color: #f0f0f0;
    font-weight: 400;
    font-size: 18px;
    width: calc(100% - 30px);
    border-radius: 10px;
    font-family: "Avenir";
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    margin-bottom: -2px;

    text-decoration-color: white;
    text-decoration-thickness: 2px;
}

textarea::placeholder {
  opacity: .5;
  color: rgb(136, 135, 135);
}


.textarea__auto{
  outline: none;
  border: none;
    background-color: #f0f0f0;
    font-weight: 300;
    font-size: large;
    width: calc(100% - 30px);
    border-radius: 10px;
    font-family: 'Rubik', sans-serif;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.button__container{
  width: calc(100% - 31px);
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -1px;
  -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.gray__box{
 padding-left: 30px;
}

.gray__box2{
  padding-left: 22px;
}

.toggle{
  background-color: #c4c4c4;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: 0px;
  margin-top: 11px;
}

.toggle__inner{
  background-color: white;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  margin-top: 2px;
  margin-left: 2px;
}

.check__class{
  position: absolute;
  margin-left: -20;
}

.check__size{
  height: 21px;
}

.continue__gray{
  position: absolute;
  background-color: blue;
  width: 100%;
}
