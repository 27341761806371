.col__container {
    height: 400px;
    width: calc(100% - 7px);
    background-color: #f0f0f0;
    margin-top: 6px;
    border-radius: 10px;
    max-width: 100%;
    overflow-x: hidden;

}

.col__top {
    display: flex;
    align-items: center;

}

.col__titlecontainer {
    flex-shrink: 1;
}

.col__playpause {
    flex-grow: 2;
    display: flex;
}

.col__timestamp {
    width: 50px;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    flex: 1;
    margin: 5px;
}

.col__buttons {
    height: 50px;
    background-color: white;
    flex: 1;
    margin: 5px;
    border-radius: 5px;
}

.col__time {
    width: 50px;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    flex: 1;
    margin: 5px;
}

/*.colab__file{
    background-color: white;
    width: 95%;
    height: 100px;
    padding-left: 7px;
    border-radius: 35px;
    margin-left: 1vw;
    top: 0.5em;
    margin-top: -100px;
    overflow:visible;  
}*/

.colab__file {
    background-color: white;
    width: 74vw;
    height: 100px;
    padding-left: 7px;
    border-radius: 35px;
    margin-left: 1vw;
    top: 0.5em;
    margin-top: -100px;
    overflow: visible;
    position: fixed;
    top: 220px;

}

.colab__fileshadow {
    width: 100%;
    height: 100px;
    margin-left: -7px;
    background-color: white;
    opacity: 1.0;
    position: absolute;
    border-radius: 35px;
    filter: drop-shadow(3px 3px 5px #f0f0f0);
}

.colab__title {
    height: 100px;
    font-weight: 700;
    padding: 10px;
    font-size: 74px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: default;
    display: flex;
    letter-spacing: -2.5px;
    align-items: center;

}

.colab__plusbuttoncontainer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.colab__plusbutton {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-color: white;
    font-size: 20px;
    padding: 0px;
    color: white;
    font-weight: 500;
    filter: drop-shadow(3px 3px 5px #e6e6e6);
}

input::file-selector-button {
    font-weight: 400;
    color: rgb(140, 140, 140);
    padding: 1rem;
    padding-inline: 2rem;
    border: thin solid rgb(211, 211, 211);
    border-radius: 5px;
    background-color: transparent;
    filter: drop
}

.colab__wave {
    padding-top: 14px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;

}

.colab__play {
    width: 30px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: default;

}

.upload__button {
    padding-top: 50px;
}

.colab__playpos {
    position: absolute;
    margin: 22px;
    margin-left: 25px;
}

.colab__time {
    position: absolute;
    color: #bfbfbf;
    font-size: 20px;
    margin-left: 20px;
    bottom: 25px;
}

.colab__bottomcontainer {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 21%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;
    width: 79%;
    backdrop-filter: blur(7px);
    padding-top: 10px;

    padding-left: 10px;
    height: 85px;
    background-color: white;
}

.colab__blur {
    height: 90px;
    margin-top: 50px;
    position: absolute;
    width: 100%;
    backdrop-filter: blur(4px);
    margin-left: -10px;

    height: 4em;
}



.colab__fade {
    /* height: 30px;
    width: 100%;
    position: absolute;
    margin-top: -70px;
    margin-left: -10px;
    backdrop-filter: blur(4px);
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, 
    from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));*/

}

.colab__textarea {
    margin-top: 10px;
    margin-left: 20px;
    width: calc(100% - 125px);
    display: flex;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding-bottom: 8px;
    margin-right: 90px;
    justify-content: space-between;
    right: 10px;
    margin-left: 15px;
    margin-top: 7px;
    margin-left: 100px;
}

.colab__text {
    resize: none;
    height: 70px;
    width: 80%;
    margin-left: 8px;
    margin-top: 5px;
}

.colab__img {
    height: 50px;
    margin-left: 13px;
    cursor: pointer;
    border-radius: 5px;
    transform: translate(0px, 2px);
    min-width: 50px;
    max-width: 50px;
    object-fit: cover;
}

.colab__messagecontainer {
    position: relative;
    overflow: visible;

    margin-top: 10px;
    background-color: #A4ECDF;
    border-radius: 10px;
    display: flex;
    margin-left: 23px;
    max-width: 20vw;

    justify-content: center;
    min-width: 75px;
    /*Member panel að skemma þetta*/
    /* z-index: -1;*/
    margin-bottom: -5px;
}



.colab__message {
    font-size: 17px;
    padding-bottom: 8px;
    margin-right: 20px;
    margin-top: 10px;
    margin-left: 20px;
    z-index: 1;
    cursor: default;
}

.bottomspace {
    height: 120px;

}

.colab__sendbutton {
    width: 35px;
    padding-left: 0px;
    margin-right: 30px;
    transform: translate(0px, 7px);
}

.colab__sendbutton:hover {
    transform: scale(1.02) translate(0px, 7px);
}

.colab__pos {
    position: absolute;
    font-size: 10px;
    margin-top: -18px;
}

.colab__addmembers {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;

    margin-right: 40px;
    margin-top: 40px;
    cursor: pointer;
    z-index: 999;
}

.colab__memberpanel {
    position: fixed;
    width: 350px;
    right: 0;
    background-color: white;
    height: 120%;
    margin-top: -20px;
    z-index: 999;
    filter: drop-shadow(-3px -3px 5px #f0f0f0);
    overflow-y: scroll;
}

.memberpanel__title {
    font-weight: 600;
    font-size: 45px;
    margin: 20px;
    margin-bottom: 0px;
}

.onemember__container {
    height: 90px;
    width: 100%;

    display: flex;

    cursor: default;
    align-items: center;
}

.onemember__container:hover {
    background-color: #f5f5f5;
}

.onemember__container.selected {
    background-color: #F0F2F5;
    border-radius: 5px;
}

.onemember__profilepic {
    width: 70px;
    background-color: aquamarine;
    height: 70px;
    border-radius: 50%;
    margin-left: 10px;
}


.onemember__profilename {
    margin-left: 10px;
    font-size: 18px;
    margin-bottom: -5px;
}

.onemember__profilerole {
    margin-left: 10px;
    font-size: 15px;
    margin-bottom: -5px;
    color: #d3d3d3;
}

.memberpanel__top {
    display: flex;
    justify-content: space-between;
}

.memberpanel__xbutton {
    padding: 20px;
    padding-top: 30px;
    cursor: pointer;
}

.colab__whitepos {

    height: 10px;
}

.colab__onecomment {
    display: flex;
}

.colab__transport {
    width: 1px;
    position: fixed;
    top: 220px;
    left: [calculated position];
    background-color: #dbd9d9;
    height: calc(100vh - 333px);
    z-index: 10;
    pointer-events: none;
}


.colab__imgcontainer {}

.colab__changecover {
    width: 100%;

    display: flex;

    height: 50vh;
}

.colab__coverimg {
    width: 25vw;
    margin-left: 18%;
    height: 25vw;
    border-radius: 7px;
    margin-top: 20px;
}

.colab__form {
    background-color: white;
    position: absolute;
    z-index: 999;
    border-radius: 10px;
    box-shadow: 3px 3px 10px #03030325;
    -webkit-user-select: none;
    user-select: none;
    position: fixed;
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.memberpanel__addplaceholder {
    padding-top: 16px;
    outline: none;
    border: none;
    border-radius: 50px;
    font-size: 22px;
    font-family: "Avenir";
    padding-left: 30px;
}

.memberpanel__plusbutton {
    margin-left: 35px;
    color: #dbd9d9;
    margin-top: -70px;
    position: absolute;
    cursor: pointer;
}

.memberpanel__text {
    margin-left: 70px;
    color: #dbd9d9;
    margin-top: -64px;
    position: absolute;
    font-size: 22px;
    pointer-events: none;
}

.memberpanel__plusbutton:hover {
    color: black;
}

.memberpanel__place {
    height: 70px;
    margin: 10px;

    border-radius: 50px;
    /* filter: drop-shadow(3px 3px 5px #f0f0f0);*/
    outline: none;
    border: none;
    width: 325px;
}

.memberpanel__shadow {
    height: 70px;
    width: 325px;
    position: absolute;
    pointer-events: none;
    margin: 10px;
    border-radius: 50px;
    margin-top: 0px;
}

.colab__behindfileblur {
    margin-bottom: 25px;
    top: 1em;
    height: 200px;
    position: relative;
    z-index: 2;
}

.colab__fileblurtest {
    width: 120%;
    backdrop-filter: blur(8px);
    height: 120%;
    margin-left: -1vw;
    position: absolute;
    margin-top: -20px;
    transform: translate(0px, 5px);
}

.colab__blurblur {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(8px);
    filter: blur(20px);
}

.colab__messageextra {
    position: relative;
    background-color: #A4ECDF;
    margin-top: -15px;
    border-radius: 0px 0px 10px 10px;
    margin-left: 23px;
    margin-bottom: 20px;
    color: white;
    font-size: 15px;
    display: flex;
    cursor: default;
    justify-content: right;
    z-index: -20;
}

.colab__messagepostext {
    position: relative;
    margin-top: 20px;
    right: 0;
    float: right;
    margin-right: 18px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.colab__messagemargin {}

body,
html {
    overflow-x: hidden;
    margin: 0;
}

.colab__deleteButton {
    position: absolute;
    font-weight: 800;
    color: white;
    top: -8px;
    /* Adjust as needed */
    right: -8px;
    /* Adjust as needed */
    font-size: 12px;
    /* Adjust font size as needed */
    /* background-color: #ADB1A9;*/
    background-color: white;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    box-shadow: 3px 3px 10px #03030318;
}

.colab__messageemoji {
    position: absolute;
    font-weight: 800;
    color: white;
    bottom: -42px;
    /* Adjust as needed */
    right: -8px;
    /* Adjust as needed */
    font-size: 12px;
    /* Adjust font size as needed */
    /* background-color: #ADB1A9;*/
    background-color: white;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    box-shadow: 3px 3px 10px #03030318;
}

.colab__comments {
    position: relative;
    z-index: -20;
}

.colab__top {
    position: fixed;
    width: 77vw;
    background-color: white;
    margin-top: -8px;
    margin-bottom: 10px;
    padding-top: 8px;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    align-items: center;
}

.onemember__textcontainer {
    font-weight: 700;
}

.lyrics__maincontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lyrics__box {
    width: 50%;
    min-height: 300px;
    background-color: white;
    box-shadow: 3px 3px 10px #03030318;
    margin-top: 30px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
    min-width: 500px;
    z-index: 1;
}

.lyrics__title {
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
}

.lyrics__lyrics {
    margin-top: 20px;
}

.input-with-suggestion {
    position: relative;
    width: 200px;
    /* Adjust width as needed */
}

.input-with-suggestion input {
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    caret-color: black;
    color: transparent;
    /* Hide text in the input */
    position: relative;
    z-index: 1;
}

.input-with-suggestion .suggestion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    /* Allow clicks to pass through */
    font-family: inherit;
    font-size: inherit;
    color: black;
    z-index: 0;
    white-space: pre;
    /* Preserve spaces */
}

.input-with-suggestion .suggestion .input-text {
    color: black;
}

.input-with-suggestion .suggestion .suggested-text {
    color: gray;
}

.colab__lufs {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 13px;
    margin-right: 40px;
}

.colab__messageprofilepic {
    position: absolute;
    border-radius: 50%;
    height: 18px;
    background-color: white;
    left: 0;
    margin-top: 5px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.colab__messagesender {
    font-size: 12px;
    position: absolute;
    margin-top: 7px;
    left: 20px;
    color: #a6a6a6;
    cursor: default;
    pointer-events: none;

}

.colab__polar {
    position: fixed;
    bottom: -88px;
    right: 0;
    width: 200px;
    height: 200px;
    margin-bottom: 13px;
    margin-right: 90px;
    /* margin-left: 30px; */
    transform: translate(10px, 0px);
}

/*.colab__messagehighlight {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 1.2em;
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
  }

  .colab__messagehighlight:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    padding: 4px;
    background: linear-gradient(45deg, #ff6ec4, #7873f5, #42e695, #ff6ec4);
    background-size: 300% 300%;
    z-index: -1;
    animation: gradientBorder 4s ease infinite;
  }

  @keyframes gradientBorder {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }*/


.colab__messagehighlight {
    position: absolute;
    width: calc(100% + 10px);
    height: 120%;
    background: rgb(247, 97, 161);
    background: linear-gradient(125deg, rgba(247, 97, 161, 1) 0%, rgba(140, 27, 171, 1) 100%);
    border-radius: 15px;
    margin-bottom: -5px;
    z-index: -99;
    transform: translate(0px, -4px);
}

.colab__messageprofilepic__size {
    width: 15px;
    border-radius: 50%;

}



.colab__emojiPopup {
    display: flex;
    font-size: 22px;
    width: 180px;
    justify-content: center;
    align-items: center;
    transform: translate(10px, 70px);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.039);
    border-radius: 30px;
    backdrop-filter: blur(10px);
}

.colab__modes {
    margin-left: 13px;
}

.colab__inviteLinkContainer {

    border-radius: 40px;
    display: flex;
    padding: 15px;
    margin: 10px;

}

.colab__inviteLinkInput {
    background: none;
    border: none;
    outline: none;
    flex: 1;
    color: white;
    font-family: "Avenir";
}

.colab__topright {
    margin-right: 2.2vw;
}

.colab__cover__formtop {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    margin-bottom: 50px;
}

.colab__cover__gap {
    height: 30px;
}

.colab__cover__img {
    width: 343px;
    height: 343px;
    background-color: #E1E1E1;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.colabToDo__buttonContainer {
    display: inline-block;
    color: #b5b5b5;
    font-size: 20px;
    margin-left: 22px;
}

.colabToDo__hoverText {
    cursor: pointer;
    text-decoration: none;
    /* If you want to add spacing around the text, you can do: */
    margin: 0 4px;
}

.colabToDo__hoverText:hover {
    text-decoration: underline;
}