.oneaudio__container {

    width: 100%;

    border-radius: 10px;
    filter: drop-shadow(3px 3px 5px #f0f0f0);


}

.playlists__songs {
    width: 60vw;
    display: flex;
    flex-flow: row wrap;
}

.oneaudio__img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.oneaudio__name {
    width: 90%;
    position: absolute;
    margin-top: -50px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: white;
    filter: drop-shadow(3px 3px 10px #101010);
    cursor: default;
    overflow: hidden;
}

.oneaudio__playbutton {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    transition: 0.15s;
}

.oneaudio__playbutton:hover {
    opacity: 100%;
}

.oneaudio__gradient {
    background: linear-gradient(odeg,
            #00000088 100%， #ffffff44 100%);
}

.plsidebar__container {
    width: 25vw;
    height: 100vh;
    margin-top: -100px;

}

.playlists__container {
    display: flex;
}

.plsidebar__title {
    font-size: 3vw;
    margin: 20px;
    letter-spacing: -0.25vw;
    font-weight: 500;
    color: #C4C4C4
}

.oneplaylist__bg {
    background-color: #EEEEEE;
    width: 100%;
    height: 100%;
    border-radius: 7px;

}

.oneplaylist__container {
    height: 40px;
    width: 22.5vw;
    margin-left: 7px;
    cursor: pointer;

}

.oneplaylist__name {
    position: absolute;
    cursor: default;
    font-size: 20px;
    margin: 6px;
    z-index: 999;
    margin-left: 10px;
    cursor: pointer;
}

.onaudio__play {
    margin-top: -100%;
    position: absolute;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 25%;
    transform: translate(0px, -12.5%);
}

.plsidebar__newplaylist {
    font-size: 1.3vw;
    margin: 20px;
    letter-spacing: -0.05vw;
    font-weight: 500;
    color: #C4C4C4;
    cursor: pointer;
}

.playlists__song-item {}

.playlist-item-name {
    font-size: 20px;
}

.playlists-container {
    display: flex;
}

.playlists-songs {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.playlists-sidebar {
    width: 30%;
    padding: 20px;
}

.playlists-sidebar-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.playlists-sidebar-list {
    display: flex;
    flex-direction: column;
}

.song-item {
    width: 150px;
    height: 150px;
    margin: 10px;

    border-radius: 5px;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
}

.song-item-name {
    text-align: center;
    padding: 10px;

}

.playlist-item {
    padding: 10px;
    margin-bottom: 10px;

    border-radius: 5px;
}

.playlist-item.drag-over {
    background-color: #cce5ff;
    border-color: #66b0ff;
}

.playlists-sidebar-add {
    padding: 10px;

    font-size: 18px;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
}

.playlists-sidebar-form {
    display: flex;
    flex-direction: column;
}

.playlists-sidebar-form input {}

.playlists-sidebar-form button {
    padding: 5px;
    margin-right: 5px;
}


.playlist-item.selected {
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.playlists-sidebar-add {
    /* Existing styles */
    margin-top: 10px;
}

.playlists-sidebar-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.playlists-sidebar-form div {
    display: flex;
    gap: 10px;
}

.playlists-sidebar-form button {
    flex: 1;
}

.playlist-item {
    cursor: pointer;
}

.playlists-sidebar-list {
    max-height: 70vh;
    overflow-y: auto;
}


/* Add these styles to your existing CSS file */

/* Styles for the playlist item content */
.playlist-item-content {

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

/* Styles for the three-dot menu icon */
.playlist-item-menu-icon {
    visibility: hidden;
    cursor: pointer;
    padding: 0 5px;
}

.playlist-item:hover .playlist-item-menu-icon {
    visibility: visible;
    /* Visible on hover */
}

/* Styles for the menu that appears when the three dots are clicked */
.playlist-item-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    min-width: 100px;
}

/* Styles for the menu options */
.playlist-item-menu-option {
    font-size: 15px;
    padding: 8px 12px;
    cursor: pointer;
}

.playlist-item-menu-option:hover {
    background-color: #f5f5f5;
}

/* Styles for the rename form */
.playlist-item-content form {

    display: flex;
    align-items: center;
}

.playlist-item-content input[type='text'] {
    border: none;
    outline: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-family: 'Avenir';
    font-size: 20px;
    background-color: #f0f0f0;
    height: 45px;
}

.playlist-input {
    border: none;
    outline: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-family: 'Avenir';
    font-size: 20px;
    background-color: #f0f0f0;
    height: 45px;
    padding-left: 10px;
}

.playlist-item-content button {
    padding: 5px 10px;
    margin-right: 5px;
}

.playlist-item-content button:last-child {
    margin-right: 0;
}