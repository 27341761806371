.invite__container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
}

.invite__cardcontainer {
    margin-left: 30vw;
    margin-top: 25vh;
    width: 730px;
    padding: 70px 0px;
    background-color: white;
    box-shadow: 3px 3px 10px #03030325;
    border-radius: 35px;
    font-size: 35px;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    letter-spacing: -0.8px;
}

.invite__button{
    background-color: black;
    color: white;
    width: fit-content;
    padding: 20px 50px;
    font-size: 18px;
    letter-spacing: -0.5px;
}

.invite__textmain{
    margin-bottom: 20px;
}

.invite__textsub{
    margin-top: -20px;
    font-size: 25px;
    margin-bottom: 20px;
}

.invite__accountbuttoncontainer
{
    display: flex;
    gap: 10px;
}