/* Chat.css */
.chat {
    font-family: Arial, sans-serif;
}

.chat__top {}

.chat__title {
    margin-top: 10px;
    font-weight: 700;
    padding: 10px;
    font-size: 50px;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    margin-bottom: -50px;
}

.chat__maincontainer {
    display: flex;
    height: calc(100vh - 50px);
    /* Adjust based on your header height */
}

.chat__chatscontainer {
    width: 29.5vw;
    height: 79vh;
    margin-top: 50px;
    background-color: white;
    padding: 1.5vw;
    border-radius: 12.5px;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
}

.chat__chats__top {
    padding: 10px;
}

.chat__chats__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.chat__chats {
    padding: 10px;

}

.chat__chatItem {
    padding: 10px;
    padding-left: 17.5px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.chat__chatItem:hover {
    background-color: #f5f5f5;
}

.chat__chatItem.selected {
    background-color: #F0F2F5;
    border-radius: 5px;
}

.chat__chatItemName {
    font-size: 23px;
    font-weight: bold;
}

.chat__lastMessage {
    margin-top: -6px;
    margin-bottom: 6px;
    color: #666;
    font-size: 14px;
}

.chat__chatcontainer {
    margin-left: 10px;
    width: 40.5vw;
    height: 79vh;
    margin-top: 50px;
    background-color: white;
    padding: 1.5vw;
    border-radius: 12.5px;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
    display: flex;
    flex-direction: column;
}

.chat__chattop {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.chat__currentchattingprofile {
    display: flex;
    align-items: center;
}

.chat__profilepic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.chat__profilepic img {}

.chat__currentchattingprofiletext {
    margin-left: 7.5px;
}

.chat__profilename {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 700;
}

.chat__messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.chat__message {
    padding: 14px;
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 60%;
    font-size: 20px;
    width: fit-content;
    word-wrap: break-word;
}

.chat__message.sent {
    background-color: #A4ECDF;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
    margin-left: auto;
}

.chat__message.received {
    background-color: #A4DFEC;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
}

.chat__bottom {
    display: flex;
    padding: 10px;

}

.chat__textbox {
    flex: 1;
    padding: 10px;
    font-size: 18px;
    font-family: "Avenir";
    outline: none;
    border: none;
    background-color: #F0F2F5;
    height: 20px;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.chat__sendbutton {
    padding: 10px 20px;
    background-color: #44CAE7;
    color: white;
    border: none;
    cursor: pointer;
    width: 117px;
    height: 61px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.8px;
}

.chat__sendbutton:hover {
    background-color: #0056b3;
}

.chat__noChatSelected {
    padding: 20px;
    text-align: center;
    color: #666;
}

.chat__messagesendsize {
    margin-top: 3px;
    width: 35px;
}

.chat__chats__searchboxstyle {
    flex: 1;
    padding: 10px;
    font-size: 18px;
    font-family: "Avenir";
    outline: none;
    border: none;
    background-color: #F0F2F5;
    height: 20px;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.chat__friendRequests{
    font-size: 12px;
}

.chat__friendsList{
    font-size: 12px;
}