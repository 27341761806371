.element__commentstitle{
    margin-left: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: default;
}

.element__comment{
    /*background-color: #A4ECDF;*/
    display: inline-block;
    font-size: 18px;
    padding: 0px;
    border-radius: 10px;
    text-decoration-color: white;
    text-decoration-thickness: 2px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
}

.element__commentcontainer{
    display: inline-block;
    padding: 3px;
    margin-left: 3px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}

.element__margin{
    margin-left: 25px;
}

.element__showcomments{
    width: 15px;
}

.element__skeleton{
    outline: none;
  border: none;
    background-color: #f0f0f0;
    font-weight: 400;
    font-size: 18px;
    width: calc(100% - 30px);
    border-radius: 10px;
    font-family: "Avenir";
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    height: 50px;
    margin-bottom: 10px;
}

.xbutton{
    width:12px;
    position: absolute;
    right: 0;
    margin-right: 8px;
    background-color: transparent;
    font-size: small;
    border-radius: 50%;
    margin-top: -9px;

    height: 4px;
  }

  .dragger{
    background-color: transparent;
    position: absolute;
    width: 2px;
    right: 0;
    margin-right: -20px;
    cursor: grab;
  }

