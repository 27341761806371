.skeleton__onebar{
    width: 5px;
    background-color: #E3E3EC;
    border-radius: 5px;
    height: 30px;
    margin-left: 1px;
}

.skeleton__container{
    width: 100%;
    display: flex;
    align-items: center;
}

.skeleton__wavecontainer{
    width: 100%;
    display: flex;
    position: absolute;
    margin-top: 33px;
    justify-content: center;
    height: 30px;
    align-items: center;
}


.skeleton__shimmerwrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    }

    .skeleton__shimmer {
    width: 30%;
    height: 180%;
    background: rgba(255, 255, 255, 0.24);
    transform: skewx(-20deg);
    box-shadow: 0 0 30px 30px rgba(255,255,255,0.3);
    animation: loading 2.5s infinite;
    z-index: 999;
    position: absolute;
    mix-blend-mode: lighten;
    }

    @keyframes loading {
    0% { transform: translatex(-150%) }
    50% { transform: translateX(-60%) }
    100% { transform: translateX(150%)}
    }



