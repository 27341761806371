.loader-container {
    width: 100px;
    height: 100px;
    margin-left: 100px;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #63B6E4 transparent #63B6E4 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

.spinner2 {
    width: 30px;
    height: 30px;
    margin-top: 9px;
    margin-left: 9px;
    border: 8px solid;
    border-color: #63B6E4 transparent #63B6E4 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.load__contain{
    margin-left: 200px;
    margin-top: 250px
}