.about__container{
    height: 70px;
    width: 100%;
    background: white;
    filter: drop-shadow(3px 3px 3px #F0F0F0);
    display: flex;
    align-items: center;
    gap: 80%;
    text-align: right;
}

.about__logocontainer{
    text-align: center;
    position: relative;
}