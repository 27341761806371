@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300&family=Rubik:wght@400;800&display=swap');

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url('./assets/Avenir-Book-01.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url('./assets/Avenir Black.ttf') format("truetype");
  font-weight: bold;
}

body {
  background: white;
  color: black;
  font-size: xx-large;

}

html {
  -webkit-font-smoothing: antialiased;
}

button {
  background-color: #f0f0f0;
  /* Green */
  border: none;
  color: rgb(173, 173, 173);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  width: calc(33% - 7px);
  font-family: "Avenir";
  font-size: small;
}

button:hover {
  /*transform: scale(1.02);*/
}

.comment__style {
  background-color: transparent;
}

.bottom {
  height: 20px;
}

.bg {
  background-color: white;
}

div {
  font-family: "Avenir";
  letter-spacing: -0.3px;
}




::selection {
  background: #e1e0e0;
}

div {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}
