/* Container for the Notes panel */
.notes__container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 450px;
    margin-right: 50px;
    margin-bottom: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    box-shadow: 3px 3px 10px #0303032e;
}

/* Close button */
.notes__closeButton {
    background: none;
    border: none;
    font-size: 16px;

    cursor: pointer;
    align-self: flex-end;
}

/* Content inside the Notes panel */
.notes__content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Note editor (upper half) */
.notes__editor {
    height: 30vh;
    margin-left: 20px;
    margin-right: 40px;
    padding: 10px;
    margin-bottom: 50px;
}

.notes__editor textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Avenir';
    letter-spacing: -0.1px;
    background-color: white;
    padding: 0px;
}

/* Chat section (lower half) */
.notes__chat {
    margin-left: 20px;
    margin-right: 20px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
}

.notes__chatMessages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.notes__chatMessage {
    margin-bottom: 10px;
    max-width: 70%;
    word-wrap: break-word;
    width: fit-content;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
}

.notes__chatMessage.sent {
    align-self: flex-end;
    background-color: #A4ECDF;
    padding: 10px;
    border-radius: 10px;
    margin-left: auto;
}

.notes__chatMessage.received {
    align-self: flex-start;
    background-color: #A4DFEC;
    padding: 10px;
    border-radius: 10px;
}

.notes__chatSenderName {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.notes__chatText {
    font-size: 16px;
}

.notes__chatInput {
    display: flex;
    padding: 5px;
    margin-bottom: 20px;
    gap: 12px;
}

.notes__chatInput input {
    flex: 1;
    padding: 15px;
    border: none;
    outline: none;
    font-size: 14px;
    box-shadow: 3px 3px 10px #00000029;
    border-radius: 10px;
    font-family: "Avenir";
}

.notes__chatInput button {
    height: 50px;
    padding: 10px 15px;
    background-color: #44CAE7;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 3px 3px 10px #00000029;
}

.notes__chatInput button:hover {
    background-color: #0056b3;
}

/* Style for the Notes button */
.files__notesButton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    z-index: 999;
    box-shadow: 3px 3px 10px #03030325;
}

/* Style for the Notes panel animation */
.files__notesPanel {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 600px;
    z-index: 1000;
}

.notes__top {
    display: flex;
    justify-content: space-between;

    margin-top: 20px;
    margin-bottom: -5px;
}

.notes__title {
    font-weight: bold;
    letter-spacing: -0.9px;
    margin-left: 30px;

}