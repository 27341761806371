.textarea__alt2{
    outline: none;
    border: none;
      font-weight: 700;
      font-size: 35px;
      width: calc(100% - 55px);
      border-radius: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      background-color: transparent;
      filter: drop-shadow(3px 3px 3px #f4f4f4);
  }