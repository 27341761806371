@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.landingpage__container {
    width: calc(100vw - 100px);
    padding: 40px;
    font-family: 'Inter', sans-serif;
}

body,
html {
    overflow-x: hidden;
}

/* Landing page specific styles */
.landing-page-body,
.landing-page-body html {
    overflow-x: visible;
}

.landingpage__container body {
    overflow-x: visible;
}

.landingpage__top {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 92vw;
    z-index: 999;
    top: 0px;
    padding: 15px;
}

.landingpage__maintitle {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    font-size: 95px;
    letter-spacing: -5px;
    font-family: 'Inter', sans-serif;
    margin-bottom: -10px;
}

.landingpage__maintitlebold {
    margin-top: -30px;
    letter-spacing: -5px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-bottom: 50px;
}

.landingpage__firstimg {
    width: 100%;
    margin-left: 80px;
    margin-right: 80px;
}

.landingpage__filesshadow {
    height: 824px;
    box-shadow: 3px 3px 10px #EAEAEA;
    margin-bottom: 100px;
    /* margin-top: 10px; */
    padding: -10px;
    width: calc(100vw - 260px);
    border-radius: 10px;
}

.landingpage__usermainpage__container {
    width: 240px;
    padding: 10px;
    background-color: white;

    /* position: fixed; */
    /* position: relative; */
    /* height: 100%; */
    height: 900px;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    display: flex;
}

.landingpage__usermainpage__dashboard {
    width: 240px;
    padding: 10px;
    background-color: white;
    filter: drop-shadow(3px 3px 5px #f0f0f0);


    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

.landingpage__usermainpage__dashgap {
    height: 250px;
}

.landingpage__colabcontainer {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 3.3772454261779785px 30.39520835876465px 0 rgba(0, 0, 0, .12);
    height: 80vh;

    padding: 8px;

    transform: translate(0vw, 0px);
    width: 90%;
    margin-left: 80px;
    overflow: hidden;

}

.landingpage__usermainpage__maintodo {
    width: 80%;
    min-height: 97vh;
    width: 80vw;
    margin-left: 230px;
}

.colabmini__file {
    background-color: white;
    width: 97%;
    height: 100px;
    padding-left: 7px;
    border-radius: 35px;
    margin-left: 1vw;
    top: 0.5em;
    /* margin-top: -100px; */
    overflow: visible;
    /* position: fixed; */
    /* margin-top: -100px; */
    box-shadow: 3px 3px 10px #03030318;
}

.landingpage__colabbehindfileblur {
    margin-bottom: 25px;
    top: 1em;
    height: 110px;
    position: relative;
    z-index: 2;
}

.landingpage__colab__bottomcontainer {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 2%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;
    width: 98%;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    padding-top: 10px;
    padding-left: 10px;
    height: 85px;
    background-color: white;
    z-index: 999;
}

.landingpage__files__notesbutton {
    position: absolute;
    /* position: fixed; */
    bottom: -260px;
    right: 160px;
    width: 60px;
    height: 60px;
    background-color: white;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 3px 3px 10px #03030325;
    top: 1100px;
}


.linear-blur {
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    pointer-events: none;
    transform-origin: center top;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: 120%;
    z-index: -1;
}

.background-layer {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 100%);
}

.blur-layer {
    position: absolute;
    z-index: 1;
    inset: 0;
}

.blur-64 {
    mask: linear-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 12.5%);
    backdrop-filter: blur(64px);
}

.blur-32 {
    z-index: 2;
    mask: linear-gradient(rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 12.5%,
            rgba(255, 255, 255, 0) 25%);
    backdrop-filter: blur(32px);
}

.blur-16 {
    z-index: 2;
    mask: linear-gradient(rgba(255, 255, 255, 0) 0%,
            rgb(255, 255, 255) 12.5%,
            rgb(255, 255, 255) 25%,
            rgba(255, 255, 255, 0) 37.5%);
    backdrop-filter: blur(16px);
}

.blur-8 {
    z-index: 3;
    mask: linear-gradient(rgba(255, 255, 255, 0) 12.5%,
            rgb(255, 255, 255) 25%,
            rgb(255, 255, 255) 37.5%,
            rgba(255, 255, 255, 0) 50%);
    backdrop-filter: blur(8px);
}

.blur-4 {
    z-index: 4;
    mask: linear-gradient(rgba(255, 255, 255, 0) 25%,
            rgb(255, 255, 255) 37.5%,
            rgb(255, 255, 255) 50%,
            rgba(255, 255, 255, 0) 62.5%);
    backdrop-filter: blur(4px);
}

.blur-2 {
    z-index: 5;
    mask: linear-gradient(rgba(255, 255, 255, 0) 37.5%,
            rgb(255, 255, 255) 50%,
            rgb(255, 255, 255) 62.5%,
            rgba(255, 255, 255, 0) 75%);
    backdrop-filter: blur(2px);
}

.blur-0-5 {
    z-index: 6;
    mask: linear-gradient(rgba(255, 255, 255, 0) 50%,
            rgb(255, 255, 255) 62.5%,
            rgb(255, 255, 255) 75%,
            rgba(255, 255, 255, 0) 87.5%);
    backdrop-filter: blur(1px);
}

.blur-0-5 {
    z-index: 7;
    mask: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 62.5%,
            rgba(255, 255, 255, 1) 75%,
            rgba(255, 255, 255, 1) 87.5%,
            rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(0.5px);
    -webkit-backdrop-filter: blur(0.5px);
}

.landingpage__notes__container {
    /* position: fixed; */
    position: absolute;
    top: 530px;
    right: 140px;
    width: 450px;
    margin-right: 50px;
    margin-bottom: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 200;
    box-shadow: 3px 3px 10px #0303032e;
    height: 600px;
}

.landingpage__notes__chat {
    margin-left: 20px;
    margin-right: 40px;
    height: 60%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
}

.landingpage__notes__editor {
    height: 20%;
    margin-left: 20px;
    margin-right: 40px;
    padding: 10px;
    margin-bottom: 50px;
}

.landingpage__finalmessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.phone-section .landingpage__maintitle {
    width: 500px;
    margin-top: 100px;
    font-size: 40px;
    gap: 20px;
    letter-spacing: -3px;
}

.phone-section .landingpage__maintitlebold {
    width: 500px;

    font-size: 40px;
    letter-spacing: -3px;
}



.desktop-view {
    display: block;
}

.phone-section {
    display: none;
}

/* On screens less than 500px, hide desktop content and show phone-only */
@media (max-width: 884px) {
    .desktop-view {
        display: none !important;
    }

    .phone-section {
        display: block !important;
    }
}

.landing__form input {
    width: 20vw;
    font-size: 15px;
    padding: 10px 15px
}


.landingpage__files__filetypebutton {
    background-color: #F9F9F9;
    width: 9.5vw;
    height: 50px;
    border-radius: 100px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px;
    margin-left: 8px;
    cursor: pointer;
}



.landingpage__files__filetypebutton:hover {
    background-color: #e0e0e0;
}

.landingpage__files__filetypebutton.selected {
    background-color: #EAEAEA;
}



@media (min-width: 1320px) {
    .landingpage__files__filetypebuttonsmall {
        display: none;
    }
}


@media (max-width: 1320px) {
    .landingpage__files__filetypebutton {
        display: none;
    }

    .landingpage__files__filetypebuttonsmall {
        display: flex;
        width: 150px;
        justify-content: space-around;
    }

    .landingpage__files__smallbutton {
        width: 40px;
        height: 40px;
        cursor: pointer;

    }
}

.landingpage__files__currentuser {
    align-items: center;
    background-color: #000;
    border-radius: 7px;
    color: #fff;
    cursor: default;
    display: flex;
    font-size: 17px;
    height: 50px;
    justify-content: center;
    margin-right: 40px;
    margin-top: 22px;
    padding-left: 55px;
    padding-right: 55px;
}

.landingpage__projects__title {
    height: 80px;
    font-weight: 700;
    padding: 10px;
    font-size: 50px;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    letter-spacing: -0.9px;
}

@media (max-width: 1300px) {
    .landingpage__files__currentuser {
        display: none;
    }
}

@media (max-width: 1060px) {
 


    .landingpage__projects__title {
        margin-top: 20px;
        font-size: 25px;
        margin-bottom: -25px;
    }
}