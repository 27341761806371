.sign__input {

    width: 100%;
    border-radius: 7px;
    font-size: 20px;
    font-weight: 100;
    outline: none;
    border: none;
    filter: drop-shadow(3px 3px 5px #ebeaea);
    width: 500px;

    border-radius: 100px;

    font-family: "Avenir";
    padding: 30px 40px;

}

.sign__form {
    height: 115px;
    display: flex;
    flex-direction: column;
}

.sign__label {
    width: 100%;

}

.sign__text {
    font-weight: 300;
    margin-bottom: 20px;
    font-size: 72.5px;
    letter-spacing: -2px;

}

.sign__container {
    display: flex;
}

.sign__button {
    margin-top: 10px;
    width: 32%;
    background-color: #000000;
    color: white;
    border-radius: 50px;
    font-size: 20px;
    filter: drop-shadow(3px 3px 5px #dddcdc)
}

.sign__button:hover {
    opacity: 90%;
    cursor: pointer;
    transform: scale(1.02);
}

.sign__up {
    margin-top: 100px;
    font-size: 18px;
    text-align: end;
}

.sign__underline {
    color: #63B6E4;
    font-size: 30px;
}

.sign__underline:hover {
    opacity: 90%;
    cursor: pointer;
    transform: scale(1.005);
}

.sign__leftcontainer {
    width: 50vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign__rightcontainer {
    width: 50vw;
    padding: 80px;
}

.sign__formcontainer {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign__error {
    margin-left: 45px;
    margin-top: 10px;
    font-size: 18px;
}