.textarea__alt{
    outline: none;
    border: none;
      font-weight: 700;
      font-size: 45px;
      width: 97%;
      border-radius: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      background-color: transparent;

  }



  .title__box{
    background-color: white;

    margin-bottom: 18px;
    width: 100%;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  